.ps-alert-error {
  margin-bottom: 0;
}

.ps-alert-error,
.ps-alert-success {
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: $brand-danger;
    border: 2px $brand-danger solid;

    i {
      display: flex;
      border: 15px $brand-danger solid;

      svg {
        width: 24px;
        height: 24px;
        background-color: $brand-danger;
      }
    }

    p {
      width: 100%;
      padding: 18px 20px;
      margin: 0;
      background-color: $body-bg;
    }
  }
}

.ps-alert-success {
  padding: 0.25rem 0.25rem 2.75rem;

  .item {
    background-color: $brand-success;
    border-color: $brand-success;

    i {
      border-color: $brand-success;

      svg {
        background-color: $brand-success;
      }
    }
  }
}
