body,html{
  overflow-x: hidden;
}
body{
  a{
    outline: none !important;
  }
  .form-control {
    padding: 0.5rem 1rem;
    color: #000;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .25);
  }
}
  .elementor-product-grid.slick-slider{
    display: block;
    .product-miniature{
      padding: 15px;
      margin-bottom: 0 !important;
      picture{
        display: flex;
        justify-content: center;
      }
    }
    .slick-list{}
  }

.ekypia_slider_home {
  .col-text{
    .titre{
      font-family: $amithen;
      font-weight: normal;
      font-size: 99px;
      letter-spacing: 0.05em;
      line-height: 94px;
      text-align: left;
      color: #fff;
    }
    .description *{
      font-family: $montserrat;
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
      text-align: left;
      color: #fff;
    }
    .btn{
      font-family: $montserrat;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 16px;
      text-align: left;
      color: #4c7360;
      text-transform: initial;
      background: #fff;
      border-color: #fff;
      border-radius: 30px;
    }
  }
}

#home_new_products{
  padding-top: 90px;
  padding-bottom: 70px;
  margin: 70px 0;
  position: relative;
  .elementor-product-grid{
    position: relative;
    z-index: 2;
    &:after{
      content: "";
      height: 450px;
      width: 320px;
      background-image: url("../img/after-slick.svg");
      position: absolute;
      display: block!important;
      background-size: contain;
      top: 0;
      background-repeat: no-repeat;
      z-index: -1;
      transform: translate(-50%, -50%);
    }
  }
  .slick-list{
    overflow: visible;
  }
  &:before{
    content: "";
    width: 100vw;
    height: 100%;
    border-radius: 0 30px 30px 0;
    background: #fff5f0;
    position: absolute;
    top: 0;
    left: -60%;
  }
  h2{
    font-family: $amithen;
    font-weight: normal;
    font-size: 87px;
    letter-spacing: 0.05em;
    line-height: 81px;
    color: #c8586c;
  }
  .elementor-text-editor *{
    font-family: $montserrat;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2d353f;
  }
  .elementor-text-editor{
    max-width: 800px;
  }
  .title_new{
    padding-left: 25%;
  }

}
.elementor-widget-text-editor .elementor-widget-container .elementor-text-editor *:not(h2) {
  font-family: $montserrat !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #2d353f !important;
}

.elementor-button-wrapper .elementor-button{
  background-color: $vert;
  padding: 15px 25px;
  border-radius: 30px;
  position: relative;
  .elementor-button-text{
    font-family: $montserrat;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: #fff;
  }
}

#selection{
  h2{
    font-family: $amithen;
    font-weight: normal;
    font-size: 87px;
    letter-spacing: 0.05em;
    line-height: 81px;
    color: #c8586c;
  }
  .elementor-text-editor{
    max-width: 100%;
    width: 1110px;
    margin: 0 auto;
  }
  .elementor-product-grid{
    position: relative;
    z-index: 2;
    &:after{
      content: "";
      height: 380px;
      width: 220px;
      background-image: url("../img/after_selection.svg");
      position: absolute;
      display: block!important;
      background-size: contain;
      top: 0;
      right: 0;
      background-repeat: no-repeat;
      z-index: -1;
      transform: translateY(-95%);
    }
  }
}


#b-affaires {
  margin: 70px 0;
  position: relative;
  &:before{
    content:"";
    width: 100vw;
    height: 100%;
    left: 50%;
    top: 0;
    background: #FFF5F0;
    position: absolute;
    transform: translateX(-50%);
  }
  .col-left{
    position: relative;
    z-index: 10;
  }
  .elementor-product-grid{
    position: relative;
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      background: #fff5f0;
      position: absolute;
      left: 0;
      top: 0;
      display: block!important;
      z-index: 2;
      transform: translateX(-100%);
    }
  }
  .slick-list{
    overflow: visible;
  }
  h2 {
    font-family: $amithen;
    font-weight: normal;
    font-size: 87px;
    letter-spacing: 0.05em;
    line-height: 81px;
    color: #c8586c;
  }

  .elementor-text-editor {
    max-width: 100%;
    width: 1110px;
    margin: 0 auto;
    h2{
      font-family: $amithen;
      font-weight: normal;
      font-size: 87px;
      letter-spacing: 0.05em;
      line-height: 81px;
      color: #c8586c;
      margin-bottom: 25px;
    }
  }

  .elementor-button-wrapper{
    margin-bottom: 25px;
  }
}

#filter-home{
  .PM_ASBlockOutput{
    width: 725px;
    max-width: 100%;
    border-radius: 30px;
    background: #fff5f0;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    margin: 0 auto;
    padding: 50px 0 20px;
    .card-block{

        padding: 0 15px;
        max-width: 450px;
        margin: 0 auto;
    }
    .card{
      background-color: transparent;
      .card-header{
        border: none;
        background-color: transparent;
        .PM_ASearchTitle{
          font-family: $amithen;
          font-weight: normal;
          font-size: 87px;
          letter-spacing: 0.05em;
          line-height: 81px;
          color: #c8586c;
        }
      }
      select{
        font-family: $montserrat;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 20px;
        text-align: left;
        color: #4c7360;
        background: #fff;
        border-color: #fff;
        border-radius: 30px;
        outline: none;
      }
      input[type="submit"]{
        background-color: #4c7360;
        padding: 12px 25px;
        border-radius: 30px;
        border-color: #4c7360;
        font-family: $montserrat;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: .05em;
        color: #fff;
        text-transform: initial;
        margin-top: 15px;
      }
      .PM_ASCriterionsGroupName{
        display: none;
      }
    }
  }
}


#discover{
  position: relative;
  &:before{
    content:"";
    width: 100vw;
    height: 100%;
    left: 50%;
    top: 0;
    background: #FFF5F0;
    position: absolute;
    transform: translateX(-50%);
  }
  h2 {
    font-family: $amithen;
    font-weight: normal;
    font-size: 87px;
    letter-spacing: 0.05em;
    line-height: 81px;
    color: #c8586c;
  }


  .swiper-container{
    border-radius: 30px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
    overflow: hidden;
  }
  .col-swiper{
    position: relative;
    top: -10%;
  }
}

#faq-home{
  h2 {
    font-family: $amithen;
    font-weight: normal;
    font-size: 87px;
    letter-spacing: 0.05em;
    line-height: 81px;
    color: #c8586c;
  }

  .subtitle{
    margin-top:-15px;
    .elementor-heading-title{
      font-family: $amithen;
      font-weight: normal;
      font-size: 33px;
      letter-spacing: 0.05em;
      line-height: 48px;
      color: #2d353f;
    }
  }
  .btns-faq{
    .elementor-row{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      >div{
        width: 100%;
        .elementor-button-link{
          width: 100%;
        }
      }
    }
  }

  .btn-white-faq{
    .elementor-button-link{
      min-width: 70%;
      background: #fff;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      .elementor-button-text{
        color: $vert;
      }

    }
  }

  .elementor-icon-list-items{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 15px;
    margin-top: 25px;
    .elementor-icon-list-item{
      align-items: center;
      .elementor-icon-list-icon{
        svg{
          width: 40px;
        }
      }
      .elementor-icon-list-text{
        padding-left: 10px;
        font-family: $montserrat;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        color: #000;
        p{
          font-family: $montserrat;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.05em;
          line-height: 20px;
          color: #2d353f;
          margin-bottom: 5px;
        }
      }
    }
  }
  .img-faq{
    position: relative;
    z-index: 2;
    &:before{
      content: "";
      height: 450px;
      width: 320px;
      background-image: url(../img/before-faq.svg);
      position: absolute;
      display: block!important;
      background-size: contain;
      top: 0;
      background-repeat: no-repeat;
      z-index: -1;
      transform: translate(-75%,-50%)
    }
    .elementor-image{
      margin-right: 20px;
      padding-top: 100%;
      height: 0;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 20px);
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      }
    }
  }
}

#filter-home .PM_ASBlockOutput .card .card-block{
  display: block;
}

#filter-home .PM_ASBlockOutput.PM_ASMobileMode .PM_ASearchTitle:after, #filter-home .PM_ASResetSearch{
  display: none !important;
}


.ek_prestablog{
  .blog-grid{
    margin-bottom: 30px;
  }
  h2.title{
    font-family: "Amithen", sans-serif;
    font-weight: 400;
    font-size: 87px;
    letter-spacing: .05em;
    line-height: 81px;
    color: #c8586c;
    text-transform: initial !important;
    margin-bottom: 25px;
    margin-top: 70px;
  }
  .description{
    max-width: 660px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
  }
  .block_cont{
    border: none !important;
  }
  .block_top{
    img{
      border-radius: 30px;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      min-height: 300px;
      object-fit: cover;
      max-width: 100%;
      width: 100%;

    }
    margin-bottom: 10px;
  }
  .block_bas{
    .cats{
      .category{
        display: inline-block;
        font-family: $montserrat;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: .05em;
        color: #2d353f;
        padding: 8px 22px;
        background: #fcd7dd;
        border-radius: 30px;
        margin: 10px 5px 10px 0;
      }
    }
    h3 a{
      text-decoration: none;
      font-family: $montserrat;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0.03em;
      line-height: 30px;
      text-align: left;
      color: #2d353f;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1530px){
  #selection .elementor-product-grid:after{
    display: none !important;
  }
}

@media (max-width: 1199px){
  .ekypia_slider_home .col-text .titre{
    font-size: 70px;
    letter-spacing: .05em;
    line-height: 75px;
  }
}

@media (max-width: 1024px) {
  #discover .col-swiper{
    top: 0;
    margin-top: 40px;
    .elementor-column-wrap{
      padding: 0;
    }
  }
  #faq-home .img-faq .elementor-image img{
    width: 100%;
  }
  #faq-home .img-faq{
    margin-bottom: 30px;
  }
}

@media (max-width: 991px){
  .ekypia_slider_home .slide .caption .content{

  }
  body .ekypia_slider_home .col-text .titre{
    font-size: 55px;
    letter-spacing: .05em;
    line-height: 58px;
  }
  body .ekypia_slider_home .img_slider{
    min-height: 85vh;
  }
  #home_new_products .title_new {
    padding-left: 15%;
  }
}

@media (max-width: 767px){

  #selection  .slick-list {
    overflow: visible;
  }
  .ekypia_slider_home .slick-track
  {
    display: flex !important;
  }

  .ekypia_slider_home .slick-slide
  {
    height: inherit !important;
  }
  /*body .ekypia_slider_home .slide .caption{
    position: initial;
    transform: none;
  }*/
  body #home_new_products .title_new{
    padding-left: 0;
  }
  #home_new_products .elementor-product-grid:after{
    display: none !important;
  }
  #home_new_products:before{
    border-radius: 0 0px 0px 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  #b-affaires h2,
  #discover h2,
  #faq-home h2,
  #filter-home .PM_ASBlockOutput .card .card-header .PM_ASearchTitle, #home_new_products h2,
  #selection h2,
  .ek_prestablog h2,
  #b-affaires .elementor-text-editor h2,
  #footer .footer-before .block_newsletter .title-newsletter,
  .ek_prestablog h2.title{
    font-size: 55px;
    letter-spacing: .05em;
    line-height: 58px;
  }
  #home_new_products {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  #b-affaires {
    margin: 40px 0;
  }
  .elementor-widget-product-grid .elementor-product-grid .product-miniature {
    margin-bottom: 0px !important;
  }
  .elementor-product-grid.slick-slider{
    margin: 0 -15px;
  }
  .ekypia_slider_home .slide .caption{
    align-items: end !important;
    .product{
      display: none;
    }
  }

  #home_new_products, #b-affaires , #discover{
    h2{
      text-align: center !important;
    }
    .elementor-text-editor{
      *{
        text-align: center !important;
      }
    }
  }
  .ek_prestablog .block_top img {
    min-height: 200px;
  }
  .ek_prestablog .block_bas h3 a{
    font-size: 18px;
    letter-spacing: .03em;
    line-height: 19px;
  }
  .ek_prestablog .block_bas h3{
    line-height: 19px;
  }
}

@media (max-width: 575px){
  #faq-home .btns-faq .elementor-row,
  #faq-home .elementor-icon-list-items{
    display: block;
    > .elementor-element{
      margin-bottom: 15px;
    }
    .elementor-icon-list-item{
      margin-bottom: 15px;
    }
  }
}
