/**
 * Typography
 * Font, line-height, and color for body text, headings, and more.
**/

$font-family-sans-serif:  "montserrat", sans-serif !default;
$font-family-serif: "montserrat", sans-serif !default;
$font-family-monospace: "montserrat", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

/**
 * Pixel value used to responsively scale all typography. Applied to the `<html>` element.
**/
$font-size-root: 16px !default;

$font-size-xs: .75rem !default; /* 12px */
$font-size-sm: .875rem !default; /* 14px */
$font-size-base: 1rem !default; /* 16px */
$font-size-lg: 1.25rem !default; /* 20px */
$font-size-xl: 1.5rem !default; /* 24px */

$line-height-base: 1.5 !default;

$font-size-h1: 3.125rem !default; /* 50px */
$font-size-h2: 2.5rem !default; /* 40px */
$font-size-h3: 2.1875rem !default; /* 35px */
$font-size-h4: $font-size-xl !default; /* 24px */
$font-size-h5: $font-size-lg !default; /* 20px */
$font-size-h6: $font-size-base !default; /* 16px */

$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

@font-face {
  font-family: Amithen;
  src: url(../font/Amithen.woff);
  font-weight: normal;
}
@font-face {
  font-family: Amithen;
  src: url(../font/Amithen_Extra.woff);
  font-weight: 900;
}

$montserrat : "montserrat", sans-serif;
$amithen : "Amithen", sans-serif;