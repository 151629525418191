.search-widget {
  display: inline-block;
  @include search-box();
}

.header-top {
  &-right {
    @include media-breakpoint-down(xs) {
      width: 100%;
      padding-right: 15px;
    }
  }

  .search-widgets {
    form {
      input[type="text"] {
        width: 100%;
        min-width: inherit;
        color: $gray-500;
        background: $gray-lighter;

        &:focus {
          background: $white;
        }
      }

      button[type="submit"] {
        top: 50%;
        bottom: inherit;
        transform: translateY(-50%);
      }
    }
  }

  // stylelint-disable-next-line
  #search_widget {
    input {
      padding-right: 1rem;
    }
  }
}

#checkout {
  #search_widget {
    display: none; /* Not ideal solution by allows to reuse same hooks/templates */
  }
}

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.page-not-found {
  .search-widget {
    form {
      display: inline-block;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      float: none;

      form {
        margin: 0 auto;

        input[type="text"] {
          min-width: inherit;
          background: $white;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search-widget {
    min-width: 15.63rem;
  }
}

@include media-breakpoint-up(sm) {
  .header-top {
    .search-widgets {
      max-width: 13rem;
    }
  }
}
