.contact-rich {
  margin-bottom: 2rem;
  color: $gray-700;
  word-wrap: break-word;

  h4 {
    margin-bottom: 2rem;
    color: $gray-900;
    text-transform: uppercase;
  }

  .block {
    height: auto;
    overflow: hidden;

    .icon {
      float: left;
      width: 3.5rem;

      i {
        font-size: 2rem;
      }
    }

    .data {
      width: auto;
      overflow: hidden;
      font-size: $font-size-sm;
      color: $gray-900;

      &.email {
        padding-top: 0.375rem;
      }
    }

    a[href^="mailto:"] {
      margin-top: 1rem;
      clear: both;

      @include media-breakpoint-up(sm) {
        font-size: 0.815rem;
      }
    }
  }
}

.contact-form {
  width: 100%;
  padding: 1rem;
  color: $gray-700;
  background: $white;

  h3 {
    color: $gray-900;
    text-transform: uppercase;
  }
}
