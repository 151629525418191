#footer {
  padding-top: 2.5rem;
}

#blockEmailSubscription_displayFooterBefore {
  float: inherit;
  width: 100%;
  margin: auto;
}

.block-contact {
  font-size: 0.875rem;
  color: $gray;

  .block-contact-title {
    color: $gray-900;
  }

  .navbar-toggler .material-icons {
    color: $gray-900;
  }

  @include media-breakpoint-down(sm) {
    #contact-infos {
      padding: 0.625rem;
      padding-top: 0;
    }
  }
}

.linklist {
  .blockcms-title a {
    color: $gray-900;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-size: $font-size-base;
  font-weight: 700;
}

.block-social {
  text-align: right;

  ul {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-flow: wrap;
    }

    li {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.125rem;
      cursor: pointer;
      background-color: $gray-light;
      background-repeat: no-repeat;

      /*@include media-breakpoint-down(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1.3rem;
        margin-bottom: 1.3rem;
        background-size: contain;
      }*/

      &:hover {
        background-color: $brand-primary;
      }

      a {
        display: block;
        height: 100%;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;

        &:hover {
          color: transparent;
        }
      }
    }
  }
}

.facebook {
  background-image: url("../img/facebook.svg");

  &::before {
    content: "";
    background-image: url("../img/facebook-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/facebook-gray.svg");

    &:hover {
      background-image: url("../img/facebook-blue.svg");
    }
  }
}

.twitter {
  background-image: url("../img/twitter.svg");

  &::before {
    content: "";
    background-image: url("../img/twitter-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/twitter-gray.svg");

    &:hover {
      background-image: url("../img/twitter-blue.svg");
    }
  }
}

.linkedin {
  background-image: url("../img/linkedin.svg");
  background-position: center center;
}

.rss {
  background-image: url("../img/rss.svg");
}

.youtube {
  background-image: url("../img/youtube.svg");
}

.googleplus {
  background-image: url("../img/gplus.svg");

  &::before {
    content: "";
    background-image: url("../img/gplus-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/gplus-gray.svg");

    &:hover {
      background-image: url("../img/gplus-blue.svg");
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-900;
  }
}

.pinterest {
  background-image: url("../img/pinterest.svg");

  &::before {
    content: "";
    background-image: url("../img/pinterest-blue.svg");
  }

  &.icon-gray {
    background-image: url("../img/pinterest-gray.svg");

    &:hover {
      background-image: url("../img/pinterest-blue.svg");
    }
  }
}

.vimeo {
  background-image: url("../img/vimeo.svg");
}

.instagram {
  background-image: url("../img/instagram.svg");
}

.footer-container {
  padding-top: $extra-large-space;
  overflow: hidden;

  .h3,
  .h4 {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  li {
    margin-bottom: 0.3125rem;
  }

  li a {
    font-size: $font-size-sm;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $brand-primary;
    }
  }
}

.links {
  .collapse {
    display: inherit;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }

  .footer-container {
    margin-top: 0;
    box-shadow: none;

    .wrapper {
      /* stylelint-disable */
      padding-right: 0 !important;
      padding-left: 0 !important;
      /* stylelint-enable */
    }

    .links {
      .h3 {
        font-size: 1rem;
        line-height: 1.5;
      }

      ul {
        margin-bottom: 0;
        background-color: $gray-lighter;

        > li {
          padding: 0.625rem;
          font-weight: 600;
          border-bottom: 1px solid $white;

          a {
            color: $gray-900;
          }
        }
      }
    }
  }

  .links {
    .collapse {
      display: none;

      &.in {
        display: block;
      }
    }

    .title {
      padding: 0.625rem;
      cursor: pointer;
      border-bottom: 1px solid $gray-lighter;

      .collapse-icons .remove {
        display: none;
      }
    }

    .title[aria-expanded="true"] .collapse-icons {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }

  .block-contact {
    padding-left: 1.5rem;
  }
}


#footer{
  .footer-before{
    background-image: url("../img/magasin-fleurs-porte-verte-vitrine-fleurs-devanture.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    &:before{
      content:"";
      width: 100%;
      height: 100%;
      background: #000;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.4;

    }
    .block_newsletter{
      background: transparent;
      max-width: 700px;
      padding: 50px 15px 65px;
      .title-newsletter{
        font-family: $amithen;
        font-weight: 400;
        font-size: 87px;
        letter-spacing: .05em;
        line-height: 81px;
        color: #fff;
        text-align: center;
      }
      .description-newsletter{
        font-family: $montserrat;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 29px;
        text-align: center;
        color: #fff;
      }
      form{
        width: 420px;
        max-width: 100%;
        margin: 0 auto;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        height: 45px;
        input[type="email"]{
          height: 45px;
          border: none;
          padding-left: 25px;
        }
        .btn-primary{
          background-color: #4c7360;
          padding: 12px 25px;
          border-color: #4c7360;
          color: #fff;
          text-transform: initial;
          border-radius: 30px;
          font-family: $montserrat;
          font-weight: bold;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.05em;
          text-align: center;
          height: 45px;
        }
      }
    }

    .block-contact{
      height: 120%;
      top: -10%;
      position: relative;
      border-radius: 30px;
      background: #fff5f0;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .h4{
        font-family: $montserrat;
        font-weight: normal;
        font-size: 20px;
        letter-spacing: 0.05em;
        line-height: 29px;
        color: #2d353f;
        text-transform: initial;
      }
    }
  }

  #contact_before_footer{
    .h3{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 20px;
      letter-spacing: 0.05em;
      line-height: 29px;
      color: #2d353f;
      margin-bottom: 20px;
    }
    .ekPhone, .ekEmail{
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $montserrat;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 24px;
        color: #2d353f;
      }
    }
    .ek_horraires{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-align: center;
      color: #2d353f;
      margin: 10px 0;
    }
  }
  .footer-container{
    .container{
      width: 1400px;
      max-width: 100%;
      .blockreassurance{
        padding: 70px 15px;
        .col-rea{
          display: flex;
          align-items: center;
          text-align: left;
          .content-rea{
            margin-left: 15px;
            p{
              margin-bottom: 0;
              font-family: $montserrat;
              font-weight: normal;
              font-size: 14px;
              letter-spacing: 0.05em;
              color: #000;
            }
            .block-title{
              font-family: $montserrat;
              font-weight: bold;
              font-size: 16px;
              letter-spacing: 0.05em;
              line-height: 20px;
              color: #2d353f;
            }
          }
        }
      }
    }
  }

  .real_footer{
    background: #FFF5F0;
    padding-top: 40px;
  }

  .body-footer{
    display: flex;
    .ek_footer{
      width: 80%;
      > .row{
        margin: 0;
      }
      .block-contact{
        display: flex;align-items: center;
      }
    }
    .logo-footer{
      width: 20%;
      .ek_address{
        font-family: $montserrat;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 24px;
        color: #2d353f;
        margin: 15px 0;
      }
      .ekEmail{
        a{
          display: flex;
          align-items: center;
          font-family: $montserrat;
          font-weight: normal;
          font-size: 16px;
          letter-spacing: 0.05em;
          line-height: 24px;
          color: #2d353f;
        }
      }
    }

    .block-contact{
      .h4{
        display: none;
      }
    }
    #contact-infos-footer{
      .ek_horraires{
        font-family: $montserrat;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 22px;
        color: #2d353f;
      }
      .ekPhone{
        a{
          display: flex;
          align-items: center;
          font-family: $montserrat;
          font-weight: normal;
          font-size: 16px;
          letter-spacing: 0.05em;
          line-height: 24px;
          color: #2d353f;
          margin-bottom: 10px;
        }
      }
    }
    .links{
      .h3{
        font-family: $montserrat;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 23px;
        color: #2d353f;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      ul{
        li{
          a{
            font-family: $montserrat;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0.05em;
            line-height: 16px;
            color: #2d353f;
          }
        }
      }
    }
  }
  .block-social{
    display: flex;
    align-items: center;
    .title{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #2d353f;
      margin-bottom: 5px;
    }
    ul{
      li{
        background-position: center;
        background-color: #fff;
        border-radius: 50%;
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16));
        margin-right: 10px;
        &.facebook{
          background-image: url("../img/facebook_artiflor.svg");
        }
        &.instagram{
          background-image: url("../img/instagram_artiflor.svg");
        }
        &.linkedin{
          background-image: url("../img/linkedin_artiflor.svg");
        }
      }
    }
  }
  #copyright{
    margin-top: 50px;
  }

  #copyright{
    p{
      color: #000;
    }
    a{
      font-family: $montserrat;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #000;
    }
  }
  #contact_before_footer{
    position: relative;
    &:after{
      content: "";
      width: 340px;
      height: 340px;
      background-image: url("../img/after-faq.svg");
      position: absolute;
      right: 0;
      transform: translate(85%, 10px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
    }
  }
}

@media (max-width: 1300px){
  #footer #contact_before_footer:after{
    display: none;
  }
}

@media (max-width: 1100px){
  #footer .body-footer .logo-footer .ekEmail a, #footer .body-footer .logo-footer .ek_address{
    font-size: 15px;
  }
  #footer .body-footer #contact-infos-footer .ek_horraires{
    font-size: 13px;
  }
}

@media (max-width: 991px){
  #footer .real_footer{
    padding-top: 25px;
    .logo-footer{
      text-align: center;
      margin-bottom: 30px;
      width: 100%;
      .ekEmail{
        a{
          justify-content: center;
        }
      }
    }
  }
  #footer .body-footer {
    display: flex;
    flex-wrap: wrap;
  }
  #footer .body-footer .ek_footer{
    width: 100%;
  }
  #footer .block-social{
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    .title{
      text-align: center;
    }
  }
}

@media (max-width: 767px){
  #footer .body-footer .ek_footer .block-contact{
    display: block;
  }
  #copyright p{
    text-align: center;
  }
  #footer{
    .navbar-toggler{
      height: auto;
      width: auto;
    }
  }
  .footer-container .links ul {
    margin-bottom: 0;
    background-color: transparent;
  }
}