#header {
  position: relative;
  z-index: 999;
  color: $gray;
  background: $white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);

  .logo {
    max-width: 100%;
    height: auto;
  }

  #_desktop_logo {
    h1 {
      margin-bottom: 0;
    }
  }

  a {
    color: $gray-900;

    &:hover {
      color: $brand-primary;
      text-decoration: none;
    }
  }

  .menu {
    display: inline-block;

    > ul {
      > li {
        display: inline-block;
      }
    }
  }

  .header-nav {
    max-height: 50px;
    border-bottom: $gray-light 2px solid;

    #menu-icon {
      margin: 0 1rem;
      vertical-align: middle;
      cursor: pointer;

      .material-icons {
        line-height: 50px;
      }
    }

    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }

    .user-info {
      margin-top: 0.9375rem;
      margin-left: 2.5rem;
      text-align: right;
      white-space: nowrap;

      .account {
        margin-left: $small-space;
      }
    }

    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }

    .cart-preview {
      &.active {
        background: $brand-primary;

        a,
        i {
          color: $white;
        }
      }

      .shopping-cart {
        color: $gray;
        vertical-align: middle;
      }

      .body {
        display: none;
      }
    }

    .blockcart {
      height: 3rem;
      padding: 0.75rem;
      margin-left: 0.9375rem;
      text-align: center;
      white-space: nowrap;
      background: $gray-light;

      a {
        color: $gray;

        &:hover {
          color: $brand-primary;
        }
      }

      &.active {
        a:hover {
          color: $white;
        }
      }

      .header {
        margin-top: 0.125rem;
      }
    }

    #_desktop_contact_link {
      display: inline-block;

      #contact-link {
        margin-top: 0.9375rem;
      }
    }

    .search-widget {
      margin-top: 0.2rem;
    }

    .material-icons {
      line-height: inherit;

      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }

  .header-top {
    padding: 1.25rem 0;

    > .container {
      position: relative;

      > .row:first-of-type {
        display: flex;
        align-items: center;
      }
    }

    .menu {
      padding-left: 15px;
    }

    .position-static {
      position: static;
    }

    a[data-depth="0"] {
      color: $gray;
      text-transform: uppercase;
    }

    .search-widget {
      float: right;
    }
  }

  .top-menu-link {
    margin-left: $medium-space;
  }

  @include media-breakpoint-down(sm) {
    .top-logo {
      display: flex;
      align-items: center;
      min-height: 50px;

      img {
        width: auto;
        max-height: 2rem;
      }

      > h1 {
        margin: 0;
      }
    }
  }
}

#header {
  .header-top {
    background-color: $rose !important;


    .ets_mm_megamenu {
      background: transparent;
      border: none;

      .ets_mm_megamenu_content {
        background: transparent;
        border: none;

        .mm_menus_ul {
          display: flex;
          align-items: center;

          .mm_menus_li {
            border: none;
            >a{
               &:before{
                 display: none;
               }
              &:hover{
                background: transparent;
                >.mm_menu_content_title{
                  color: #4c7360;
                }

              }
            }

            &.item_products {
              margin-right: 10px;
              > .ets_mm_url {
                background-color: #4c7360 !important;
                padding: 13px;
                border-radius: 30px;

                &:after, &:before {
                  display: none;
                }
              }

              > .ets_mm_url > .mm_menu_content_title {
                color: #fff;

                &:before {
                  content: "";
                  width: 28px;
                  height: 18px;
                  background-image: url("../img/menu_produits.svg");
                  display: inline-block;
                  margin-right: 10px;
                  background-size: contain;
                  background-position: center;
                }
              }
            }
          }

          .mm_menu_content_title {
            font-family: $montserrat;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            text-align: left;
            color: #2d353f;
            min-height: auto;
            text-transform: initial;
            padding: 0 8px;
          }
        }
      }
    }

  }

  #search_widget {
    max-width: 190px;
    min-width: auto;

    input {
      background: $vert;
      border-radius: 23px;
      font-family: $montserrat;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 20px;
      color: #fff;
      padding: 13px 40px 13px 20px;

      &::placeholder {
        font-family: $montserrat;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 20px;
        color: #fff;
        opacity: 1;
      }
    }

    i {
      right: 12px;
      left: auto;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      font-size: 0;
      background-image: url("../img/search.svg");
    }
  }

  .header-top-right {
    display: flex;
    align-items: center;
  }

  .user-info, .blockcart.cart-preview {
    background: $vert;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    margin-left: 25px;
    justify-content: center;
    border-radius: 50%;
  }
}

#jolisearch_mobile_modal{
  padding: 105px 0px 3rem;
  #jolisearch_mobile_header{
    .h1{
      font-size: 45px;
      letter-spacing: .05em;
      line-height: 50px;
      font-family: $amithen;
      font-weight: 400;
      color: #c8586c;
    }
    .jolisearch-modal__close{
      color: #c8586c;
      opacity: 1;
    }

  }
  .ui-menu.ui-jolisearch{
    border-radius: 0;
  }
}


@media (min-width: 1200px){
  .ets_mm_megamenu_content .ets_mm_megamenu_content_content,
  .col-menu,
  .ets_mm_megamenu,
  .p-initial {
    position: initial !important;
  }
  .ets_mm_megamenu .mm_columns_ul:before {
    height: 35px !important;
  }

  #header .mm_menus_li .mm_columns_ul_tab {
    background: #fff !important;
    top: 0 !important;
    margin-top: 90px !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    &:after {
      content: "";
      height: 100%;
      width: 350px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background: #fff5f0;
      background-image: url("../img/pseudo_menu.svg");
      background-size: 250px;
      background-repeat: no-repeat;
      background-position: bottom center;
      //filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16));
    }

    .mm_tabs_li {
      padding-left: 50px;

      &:first-child {
        margin-top: 50px;

      }

      .mm_tab_li_content {
        border: none;
        background: transparent;

        .mm_tab_toggle_title {
          font-family: $montserrat;
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
          color: #2d353f;
        }

        .mm_tab_name {
          &:after {
            display: none;
          }
        }
      }
    }

    .mm_columns_contents_ul {
      padding: 50px;
      border: none;
    }

    .mm_block_type_html {
      .h4 {
        border: none;
        margin-bottom: 0;

        a {
          font-family: $montserrat;
          font-weight: bold;
          font-size: 22px !important;
          line-height: 30px;
          text-align: left;
          color: #2d353f;
          text-transform: initial;
          &:hover{
            color: #4c7360;
          }
        }
      }

      .ets_mm_block_content {
        ul {
          li {
            a {
              font-family: $montserrat;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #2d353f;
              &:hover{
                color: #4c7360;
              }
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

}




body{
  .ui-menu.ui-jolisearch{
    border-radius: 30px;
    background: #fff;
    -webkit-box-shadow: 0 3px 16px rgba(0,0,0,.16);
    box-shadow: 0 3px 16px rgba(0,0,0,.16);
    border: none;
  }
}

.ets_mm_megamenu{
  .ets_mm_megamenu_content_content .ybc-menu-toggle{
    padding: 0;
    font-size: 0;
    background: #fff5f0;
    .icon-bar{
      height: 3px;
      margin: 4px 0px 1px 0;
      width: 30px;
      background-color: #4c7360;
    }
  }
  &.ets_mm_megamenu{
    padding: 0 !important;
    .ets_mm_megamenu_content_content .ybc-menu-toggle{
      padding: 0;
      font-size: 0;
     // display: flex;
      align-items: center;
    }
  }
  &.changestatus{
    .ets_mm_megamenu_content_content .ybc-menu-toggle{
      display: flex;
    }
  }
}

.layout_layout1 .ets_mm_block_content ul li a::before{
  &:before{
    display: none;
  }
}

.mm_menus_li.mm_has_sub.mm_no_border > a::after{
  display: none !important;
}

@media (min-width: 1200px){
  #search_mobile{
    display: none;
  }

}


@media (max-width: 1460px) {
  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul .mm_menu_content_title {
    font-size: 16px;
    line-height: 18px;
  }
  #header .blockcart.cart-preview, #header .user-info {
    width: 40px;
    height: 40px;
  }
  #header #search_widget input {
    padding: 10px 40px 10px 20px;
  }
}

@media (max-width: 1360px) {
  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul .mm_menu_content_title {
    font-size: 15px;
    line-height: 16px;
    padding: 0 6px;
  }
  #header .blockcart.cart-preview, #header .user-info {
    margin-left: 15px;
  }
}



@media (max-width: 1399px){
  #header .mm_menus_li .mm_columns_ul_tab .mm_block_type_html .h4 a,
  #header .mm_menus_li .mm_columns_ul_tab .mm_tabs_li .mm_tab_li_content .mm_tab_toggle_title{
    font-size: 19px;
    line-height: 27px;
  }
  #header .mm_menus_li .mm_columns_ul_tab .mm_block_type_html .h4 a {
    font-size: 19px !important;
  }
  #header .mm_menus_li .mm_columns_ul_tab .mm_block_type_html .ets_mm_block_content ul li a{
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 1299px) and (min-width: 1200px) {
  #header .mm_menus_li .mm_columns_ul_tab .mm_columns_contents_ul {
    padding: 50px 20px;
    border: 0;
  }
}

@media (max-width: 1199px) {
  .layout_layout1 .ets_mm_block_content ul li a::before{
    display: none;
  }
  #_desktop_logo {
    order: 2;
  }
  .col-menu {
    order: 1;

    .mm_menus_ul {
      display: flex;
      justify-content: center;
    }
  }
  .header-top-right {
    order: 3;
    justify-content: flex-end;
  }
  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul .mm_menu_content_title {
    font-size: 18px;
    line-height: 20px;
    padding: 0 6px;
  }

  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul{
    display: block;
  }
  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul .mm_menus_li.item_products>.ets_mm_url{
    border-radius: 0;
  }

  #header{
    #search_widget{
      width: 0;
      opacity: 0;
    }
    #search_mobile{
      width: 40px;
      height: 40px;
      background: #4c7360;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg{
        width: 17px;
      }
    }
  }

  #header .blockcart.cart-preview, #header .user-info {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .ets_mm_megamenu.hook-default{
    margin-top: 0 !important;
  }
  #_desktop_logo{
    padding: 0;
  }
  #header .header-top {
    padding: 10px 0;
  }
  #header .blockcart.cart-preview, #header .user-info {
    margin-left: 10px !important;
  }
}

@media (max-width: 1199px) {
  body .changestatus.ets_mm_megamenu .mm_menus_ul .close_menu {
    color: #4c7360;
    background: #fff5f0;
    .icon-bar{
      background-color: #4c7360;
    }
    .mm_menus_back_icon{
      border-left: 2px solid #4c7360;
      border-top: 2px solid #4c7360;
    }
  }
  body .changestatus .mm_menus_ul .arrow{
    &:before{
      border-top: 1px solid #fff !important;
    }
    &:after{
      border-left: 1px solid #fff !important;
    }
  }
  .layout_layout1 .ets_mm_block_content ul li a::before{
    display: none !important;
  }
  #header .header-top .ets_mm_megamenu .ets_mm_megamenu_content .mm_menus_ul .mm_menus_li{
    margin-bottom: 10px;
  }
  body .transition_floating .close_menu *, .transition_full .close_menu *, .ybc-menu-vertical-button .icon-bar{
    color: #4c7360;
  }
}
