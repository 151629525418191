/* stylelint-disable */
@import "~manrope/complete/font.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src:
      local("Material Icons"), local("MaterialIcons-Regular"), url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2)
      format("woff2"), url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff)
      format("woff"), url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf)
      format("truetype");
}
/* stylelint-enable */

.material-icons {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-family: "Material Icons", Arial, sans-serif;
  font-size: 24px; /* Preferred icon size */
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  vertical-align: middle;
  direction: ltr;
  
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  
  /* Support for IE. */
  font-feature-settings: "liga";
}

$headings: [h1,h2,h3,h4,h5,h6];

@each $heading in $headings {
  #{$heading}, .#{$heading} {
    font-family: $headings-font-family;
    text-transform: none;
    font-weight: $font-weight-medium;
    margin: 0;
  }
}

h1, .h1 {
  font-size: $font-size-h2;
}

h2, .h2 {
  font-size: $font-size-h2;
}

h3, .h3 {
  font-size: $font-size-h3;
}

h4, .h4 {
  font-size: $font-size-h4;
}

h5, .h5 {
  font-size: $font-size-h5;
}

h6, .h6 {
  font-size: $font-size-h6;
}

@include media-breakpoint-up(md) {
  h1, .h1 {
    font-size: $font-size-h1;
  }
}