#module-wkshopthelook-wkshopcollection{

  #content-wrapper{
    h1{
      font-family: $montserrat;
      font-size: 32px;
      letter-spacing: .03em;
      line-height: 40px;
      margin-bottom: 15px;
      color: #2d353f;
      font-weight: 700;
    }
    .product-prices{
      .product-price{
        font-family: "montserrat", sans-serif;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: .03em;
        line-height: 40px;
        color: #f19bab;
        .wk-stl-collection-price{
          display: flex;

          .tax-shipping-delivery-label{
            font-family: $montserrat;
            font-weight: 700;
            font-size: 32px;
            letter-spacing: .03em;
            line-height: 40px;
            color: #f19bab;
            margin-bottom: 0;
            margin-left: 10px;
          }
        }

      }
    }
    .product-accessories{
      h2{
        font-family: $amithen;
        font-weight: 400;
        font-size: 87px;
        letter-spacing: .05em;
        line-height: 81px;
        color: #c8586c;
        position: relative;
        text-transform: initial !important;
        margin-bottom: 40px;
        &:first-letter{
          text-transform: uppercase !important;
        }
      }
      .add-to-cart{
        margin-left: 0 !important;
      }
    }

    .images-container{
      .wk_look_cover_img{
        padding-right: 15px;

        .wk_look_cover_img_inside{
          border-radius: 30px;
          background: #fff;
          box-shadow: 0 3px 16px rgba(0,0,0,.16);
          overflow: hidden;
        }
      }

      .product-images{
        .thumb-container{
          img{
            border-radius: 15px;
            background: #fff;
            -webkit-box-shadow: 0 3px 16px rgba(0,0,0,.16);
            box-shadow: 0 3px 16px rgba(0,0,0,.16);
            overflow: hidden;
          }
        }
      }
    }

    .wk-front-collection-selected-product{
      .wk-collection-look-product{
        border-radius: 30px;
        background: #fff;
        box-shadow: 0 3px 16px rgba(0,0,0,.16);
        margin-bottom: 15px;
      }
    }
    .wk-choose-option{
      span{
        font-family: $montserrat;
        font-size: 22px;
        letter-spacing: .03em;
        line-height: 24px;
        color: #2d353f;
        font-weight: 700;
        margin-bottom: 20px;
        display: inline-block;
        text-decoration: underline;
      }
    }
  }

  .description *{

    font-family: $montserrat;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .05em;
    line-height: 24px;
    color: #2d353f;
  }

  .wk-looks{
    .control-label{
      margin-bottom: 15px;
    }
    .image-selected {
      border: 3px solid #4c7360;
    }
  }

  .look-product-not-available{
    margin-bottom: 15px;
  }
}

.wk_ajax_add_to_cart_button{
  &:hover, &:focus{
    svg{
      path{
        fill:#4c7360;
      }
    }
  }
}