$thumbnail-size: 250px;
$product-description-height: 70px;

#products,
.featured-products,
.product-accessories {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .products-section-title {
    margin: 2.5rem 0;
    font-weight: 500;
    text-align: center;
  }

  .all-product-link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    clear: both;
    font-weight: 500;
    color: $gray-700;

    @at-root .lang-rtl & {
      .material-icons {
        transform: rotate(-180deg);
      }
    }
  }
}

.product-miniature {
  display: flex;
  justify-content: center;

  .product {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0.8125rem;

    @at-root .page-index &, .page-search & {
      width: 25%;
      min-width: 250px;
    }
  }

  .product-thumbnail {
    display: block;
  }

  .product-title a {
    font-size: $font-size-sm;
    font-weight: 400;
    color: $gray-700;
    text-align: center;
    text-decoration: none;
  }

  .thumbnail-container {
    position: relative;
    height: auto;
    margin-bottom: 1.563rem;
    overflow: hidden;
    background: $white;

    &:hover,
    &:focus {
      .highlighted-informations {
        top: calc(100% - 4.4rem);

        &::after {
          opacity: 1;
        }

        &.no-variants {
          top: calc(100% - 2.5rem);
        }
      }

      .product-description::after {
        position: absolute;
        top: 0;
        left: 10%;
        width: 80%;
        content: "";
        border-top: $gray-700 1px solid;
        opacity: 0.25;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }

    .product-thumbnail {
      position: relative;
      height: calc(100% - #{$product-description-height});

      img {
        position: relative;
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }
  }

  .product-title {
    margin-top: 0.7rem;
    text-align: center;
    text-transform: capitalize;
  }

  .product-price-and-shipping {
    font-weight: 700;
    color: $gray-900;
    text-align: center;

    .discount-product {
      display: none;
    }
  }

  .variant-links {
    position: relative;
    top: -0.25em;
    width: 100%;
    min-height: 2.5rem;
    padding-top: 0.1875rem;
    text-align: center;
    background: $white;
  }

  .thumbnail-top {
    position: relative;
    overflow: hidden;
  }

  .highlighted-informations {
    position: absolute;
    top: 100%;
    z-index: 2;
    width: 100%;
    height: auto;
    padding: 0.625rem 0;
    text-align: center;
    background: $white;
    transition: 0.3s;

    @include media-breakpoint-down(xs) {
      display: none;
    }

    .quick-view {
      font-size: $font-size-base;
      color: $gray-700;

      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    position: relative;
    bottom: 0;
    height: auto;
    padding: 0.25rem;
    padding-bottom: 0.7rem;
    background: $white;
  }

  .product-flags {
    li.product-flag {
      min-width: 3.125rem;
      min-height: 1.875rem;
      font-weight: 600;

      &.online-only {
        top: 13.1rem;
      }
    }
  }

  .comments_note {
    color: $gray-700;
    text-align: center;
  }

  .regular-price {
    display: inline-block;
    font-size: $font-size-sm;
    color: $gray-700;
    text-decoration: line-through;
  }

  .count {
    position: relative;
    bottom: 0.5rem;
    font-weight: 700;
    color: $gray-700;
  }
}

@include media-breakpoint-down(md) {
  .featured-products {
    .products {
      justify-content: center;
    }
  }
}
