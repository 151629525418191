#blockcart-modal{
  &.modal.fade .modal-dialog__offcanvas {
    width: 350px;
    height: 100%;
    margin: 0;
    max-width: 90%;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform .3s ease-out;
  }

  &.modal.fade.in .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
    transform: translateX(0);
  }

  &.modal.fade .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
    right: 0;
    transform: translateX(100%);
  }

  &.modal.fade.in .modal-dialog__offcanvas.modal-dialog__offcanvas--right {
    transform: translateX(0);
  }

  .modal-dialog__offcanvas .modal-content {
    height: inherit;
    border-radius: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.5);
  }

  .modal-dialog__offcanvas .modal-header {
    border-radius: inherit;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: none;
    background: #fff5f0;
    .modal-title{
      font-family: "montserrat", sans-serif;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px;
      text-align: left;
      color: #2d353f !important;
    }
    &:after{
      display: none;
    }
  }

  .modal-dialog__offcanvas .modal-body {
    overflow-y: auto;
    height: inherit;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    .product-title{
      letter-spacing: .03em;
      text-transform: uppercase;
      font-family: $montserrat;
      font-size: 14px;
      line-height: 19px;
      color: #2d353f;
      font-weight: 700;
    }
    .product-image{
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 3px 7px rgba(0,0,0,.16);
      overflow: hidden;
    }
    .product-price-and-shipping{
      font-family: "montserrat", sans-serif;
      .regular-price{
        font-family: "montserrat", sans-serif;
        color: #4c7360;
        margin-right: .625rem;
        font-weight: 500;
        text-decoration: line-through;
      }
      .price{
        font-family: "montserrat", sans-serif;
        font-weight: 700;
        letter-spacing: .03em;
        color: #f19bab;
      }
    }
    .remove-from-cart{
      color: #4c7360;
    }
  }
  .d--flex-between{
    display: flex;
    justify-content: space-between;
  }

  .js-cart-line-product-quantity{
    display: block;
    width: 3rem;
    height: 2.75rem;
    padding: 0.175rem 0.5rem;
    color: #232323;
    background-color: #fff;
  }
  .modal-footer{
    .btn-outline-secondary{
      background: #fff;
      border-color: #4c7360;
      padding: 10px 25px;
      border-radius: 30px;
      font-size: 17px;
      color: #4c7360;
    }
    .btn-primary{
      background: #4c7360;
      border-color: #4c7360;
      padding: 10px 25px;
      border-radius: 30px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}




