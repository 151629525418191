#product {
  .alert-warning {
    margin: 110px auto 20px;
  }

  #content {
    max-width: 570px;
    margin-bottom: 30px;
  }
  
  .page-content {
    .images-container {
      margin-bottom: 15px;
      
      .product-cover {
        margin-bottom: 15px;
      }
      
      .js-qv-mask.scroll {
        width: 100%;
      }
      
      .product-images {
        margin-bottom: 0;
      }
      
      .thumb-container {
        display: inline-block;
        width: fit-content;
        
        .thumb {
          margin: 0 12px 0 0;
        }
      }
    }
    
    .scroll-box-arrows {
      width: fit-content;
      margin: auto;
      
      @include media-breakpoint-up(xl) {
        &.is-hidden {
          display: none;
        }
      }
      
      i {
        position: relative;
        bottom: unset;
        height: 40px;
        width: 40px;
        line-height: 40px;
        color: $white;
        font-size: $font-size-h2;
        z-index: 10;
        
        &.left {
          margin-right: 15px;
        }
        
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: -10;
          width: 100%;
          height: 100%;
          background: $brand-primary;
          opacity: 0.7;
          transition: opacity 250ms ease-in-out;
        }
        
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  
  .product-header {
    margin-bottom: 30px;
    .product-title {
      margin-bottom: 10px;
    }
    .product-header-infos {
      display: flex;
      gap: 20px;
      font-size: $font-size-sm;
      
      .product-reference {
        color: $gray-600;
        
        label {
          margin: 0;
          color: $gray-600;
        }
      }
      
      #product-availability {
        margin: 0;
        font-weight: $font-weight-semibold;
      }
    
    }
  }
  
  .product-description {
    p {
      font-weight: $font-weight-light;
      margin-bottom: 0;
    }
  }


  h1{
    font-family: $montserrat;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: 0.03em;
    line-height: 40px;
    color: #2d353f;
    margin-bottom: 15px;
  }
  h2, h3 , h4{
    font-family: $montserrat;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 0.03em;
    line-height: 35px;
    color: #2d353f;
    margin-bottom: 10px;
  }

  .product-information{
    .product-description{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 24px;
      color: #2d353f;
      a{
        color: #2d353f;
        font-weight: bold;
      }
    }

    .product-prices{
      display: flex;
      align-items: center;
      font-family: $montserrat;
      font-weight: bold;
      font-size: 32px;
      letter-spacing: 0.03em;
      line-height: 40px;
      color: #f19bab;
      .product-discount{
        margin-bottom: 0;
        .regular-price{
          color: #4c7360;
        }
      }
      .product-price{
        font-family: $montserrat;
        font-weight: bold;
        font-size: 32px;
        letter-spacing: 0.03em;
        line-height: 40px;
        color: #f19bab;
        margin-bottom: 0;
        *{
          margin-bottom: 0;
        }
        .current-price{
          display: flex;
          align-items: center;
        }
        .discount{
          font-size: 12px;
          border-radius: 5px;
          padding: 5px 10px;
          height: auto;
          line-height: 15px;
          background: #4c7360;
          display: none;
        }
      }
      .tax-shipping-delivery-label{
        font-family: $montserrat;
        font-weight: bold;
        font-size: 32px;
        letter-spacing: 0.03em;
        line-height: 40px;
        color: #f19bab;
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }

  .page-cover-thumbnails{
    max-width: 100% !important;
    .products-imagescover{
      border-radius: 30px;
      background: #fff;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      overflow: hidden;
    }

    .product-thumbs{
      margin-top:  -15px;
      .product-thumb{
        padding: 15px;
        .rc{
          border-radius: 30px;
          background: #fff;
          box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
          overflow: hidden;
          padding: 10px;
          > img{
            border-radius: 30px;
            aspect-ratio: 1 / 1.1;
            object-fit: cover;
          }
        }
      }
    }
  }

  .product-reference-top{
    margin: 15px 0 25px;
    .sku{
      background: #fcd7dd;
      font-family: $montserrat;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #2d353f;
      padding: 10px 20px;
      border-radius: 30px;

    }
  }

  #product-availability{
    background: #fcd7dd;
    font-family: $montserrat;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: .05em;
    text-align: left;
    color: #2d353f;
    padding: 10px 20px;
    border-radius: 30px;
    display: inline-block;
    margin-top: 0;
  }
  .product-discounts{
    margin-bottom: 0;
  }

  .col-availability, .col-qty{
    display: flex;
    align-items: center;

    .product-minimal-quantity{
      margin-bottom: 0;
      margin-left: 10px;
      font-family: $montserrat;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 24px;
      color: #2d353f;
    }
  }

  .product-add-to-cart{
    .control-label{
      font-family: $montserrat;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 20px;
      color: #2d353f;
      display: inline-block;
      width: auto;
      margin-right: 10px}
    .col-add{
      margin-top: 20px;
      .add-to-cart{
        font-family: $montserrat;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 20px;
        text-align: left;
        color: #fff;
        display: flex;
        align-items: center;
        background: #4c7360;
        border-color: #4c7360;
        border-radius: 300px;
        padding: 10px 25px;
        height: auto;
        svg{
          margin-right: 15px;
          margin-left: 5px;
        }
      }
    }
  }

  #accordion{
    margin-top: 35px;

    .card-header{
      padding: 0;
      background: transparent;
      border: none;
      >.btn{
        background: #fff;
        border-color: #fff;
        border-radius: 300px;
        padding: 14px 25px;
        width: 100%;
        text-align: left;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
        font-family: $montserrat;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 20px;
        color: #4c7360;
        text-transform: uppercase;
        text-decoration: none;
        outline: none;
        &:after{

        }
      }
    }
    .collapse{
      margin-top: 25px;
    }
  }

  .position-relative{
    position: relative;

    .btn-zoom{
      position: absolute;
      bottom: 30px;
      right: 0;
      outline: none;
      .zoom-in{
        font-size: 35px;
        color: #4c7360;
      }
    }
  }

  .footer-products{
    margin: 50px -15px;
    position: relative;
    .content{
      position: relative;
      z-index: 3;
      .h5{
        font-family: $amithen;
        font-weight: normal;
        font-size: 87px;
        letter-spacing: 0.05em;
        line-height: 81px;
        color: #c8586c;
        text-transform: initial !important;
        margin-bottom: 30px;
      }
      p:not(.h5){
        font-family: $montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2d353f;
        width: 325px;
        max-width: 100%;
      }
    }
    .products-slick{
      position: relative;
      &:before{
        content: "";
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        left: 0;
        top: -15px;
        display: block !important;
        z-index: 2;
        transform: translateX(-100%);
      }
      .slick-list{
        overflow: visible;
      }
      .product-miniature{
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }

  #home_new_products{
    h2{
      position: relative;
      z-index: 2;
    }
    .products-slick{
      position: relative;
      &:after{
        content: "";
        width: 100%;
        height: 100%;
        background: #fff5f0;
        position: absolute;
        left: 0;
        top: -15px;
        display: block !important;
        z-index: 2;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }

  }

  .position-relative .product-flags{
    align-items: end;
    padding: 10px 15px;
    .product-flag , .product-flag.on-sale{
      font-family: $montserrat;
      font-weight: 500 !important;
      font-size: 16px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #2d353f;
      text-transform: initial;
      margin-top: 0.625rem !important;
      width: auto;
      background: #FCD7DD !important;
      border-radius: 30px;
      padding: 10px 20px;
      &.discount{
        display: none;
      }

    }

  }

  .totshowmailalerts_product{
    border-radius: 20px;
    button{
      color: #fff;
      background: #4c7360;
      border-color: #4c7360;
      padding: 10px 25px;
      height: auto;
      border-radius: 30px;
    }
  }

  #home_new_products{
    .products-slick{
      position: relative;
      /*&:before{
        content: "";
        height: 450px;
        width: 320px;
        background-image: url(../img/after-slick.svg);
        position: absolute;
        display: block !important;
        background-size: contain;
        top: 0;
        background-repeat: no-repeat;
        z-index: 2;
        transform: translate(-50%, -50%);
      }*/
    }
  }

}
.product-thumbnail{
  picture{
    display: flex;
    justify-content: center;
  }
}

.description_container{
  position: relative;
  padding: 50px 0;
  margin: 50px 0;
  &:before{
    content: "";
    width: 100vw;
    height: 100%;
    background: #fff5f0;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
  }
  h2{
    text-transform: uppercase;
  }
  a{
    color: #2d353f;
    font-weight: 700;
  }
  .product-description{
    *{
      color: #2D353F;
    }
  }
}


@media (min-width: 1200px){
  .images-container {
    display: flex;
    justify-content: flex-end;
     .position-relative {
      width: 80%;
      display: inline-block;
    }
    .product-thumbs{
      width: 20% !important;
      display: inline-block;
      height: 100%;
      padding: 0;
    }
  }
}


@media (max-width: 991px){
  #product .footer-products .content .h5{
    text-align: center;
  }
  #product .footer-products .content p:not(.h5){
    text-align: center;
    margin: 0 auto 30px;
  }
}

@media (max-width: 767px){
  #product .footer-products .content .h5,
  #product #home_new_products h2{
    font-size: 55px;
    letter-spacing: .05em;
    line-height: 60px;
    text-align: center;
  }
  #product .page-cover-thumbnails .product-thumbs .product-thumb {
    padding: 8px;
  }
  product .page-cover-thumbnails .product-thumbs .product-thumb .rc {
    padding: 5px;
  }
}