#products {
  color: $gray-700;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin-bottom: 1rem;

    .btn-secondary {
      color: $gray-700;
      text-transform: inherit;

      .material-icons {
        margin-right: 0;
      }
    }
  }
}

.block-category {
  min-height: 13.75rem;
  margin-bottom: 1.563rem;

  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  .block-category-inner {
    display: flex;
    justify-content: space-between;

    #category-description {
      p,
      strong {
        font-weight: 400;
        color: $gray-700;
      }

      p {
        margin-bottom: 0;
        color: $gray-900;
        word-break: break-word;

        &:first-child {
          margin-bottom: $medium-space;
        }
      }
    }

    .category-cover {
      img {
        width: 141px;
        height: 180px;
      }
    }
  }
}

.products-selection {
  .sort-by-row {
    display: flex;
    align-items: center;
  }

  .sort-by {
    margin-right: -0.9375rem;
    margin-left: 0.9375rem;
    text-align: right;
    word-break: break-word;
    white-space: normal;
  }

  .total-products {
    padding-top: 0.625rem;
  }

  h1 {
    padding-top: 0.625rem;
  }

  p,
  .sort-by-row {
    &,
    .sort-by,
    .select-title {
      font-size: 0.875rem;

      i {
        height: inherit;
        line-height: inherit;
      }
    }
  }

  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

.products-sort-order,
.brands-sort,
.suppliers-sort {
  color: $gray-700;

  .select-title {
    display: inline-block;
    width: 100%;
    padding: $small-space;
    color: $gray-900;
    cursor: pointer;
    background: $white;
    border: $input-btn-border-width solid $input-border-color;
  }

  .select-list {
    display: block;
    padding: $small-space $medium-space;
    color: $gray-900;

    &:hover {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
    }
  }

  .dropdown-menu {
    left: auto;
    width: 16.88rem;
    margin: 0;
    background: $gray-300;
    border: none;
    border-radius: 0;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.facet-dropdown {
  box-sizing: border-box;
  padding-right: 0;
  padding-left: 0;
  color: $gray-700;
  background: $gray-500;
  border: 3px solid transparent;
  box-shadow: 1px 1px 1px 1px $gray-500;

  &.open {
    border: 0;

    > .select-title {
      background: $gray-300;
      border: 3px solid $brand-primary;
    }
  }

  .select-title {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: $small-space $small-space $small-space $medium-space;
    margin-left: 0;
    color: $gray-900;
    cursor: pointer;
    background: $gray-500;

    > i {
      margin-left: auto;
    }
  }

  .select-list {
    display: block;
    padding: $small-space $medium-space;
    color: $gray-900;
    background: $gray-300;

    &:hover {
      color: $white;
      text-decoration: none;
      background: $brand-primary;
    }
  }

  .dropdown-menu {
    left: auto;
    width: 100%;
    padding: 0;
    margin-top: 3px;
    background: $gray-300;
    border: none;
    box-shadow: 1px 1px 1px 1px $gray-500;
  }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  padding: 1.563rem 1.25rem;
  margin-bottom: 1.563rem;
  background: $white;

  > .h6 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .facet {
    padding-top: $small-space;

    .collapse {
      display: block;
    }

    .facet-title {
      font-size: 1rem;
      color: $gray-900;
    }

    .facet-label {
      margin-bottom: 0;

      a {
        display: inline-block;
        margin-top: 0.4375rem;
        font-size: $font-size-lg;
        color: $gray-900;
      }
    }
  }
}

#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;

    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  width: 100%;
  font-size: 0.875rem;

  > div:first-child {
    padding-left: 0;
    line-height: 2.5rem;
  }

  .page-list {
    padding: 0.375rem;
    margin-bottom: 0;
    background: $white;

    li {
      display: inline;
    }
  }

  a {
    font-weight: 600;
    color: $black;

    &:not(.previous):not(.next) {
      letter-spacing: 0.125rem;
    }
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }

  .disabled {
    color: $gray-700;
  }

  .current a {
    font-size: 1.25rem;
    color: $brand-primary;
    text-decoration: none;
  }
}

.active_filters {
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;
  background: #dededd;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 600;
  }

  ul {
    display: inline;
  }

  .filter-block {
    display: inline-block;
    padding: 0.625rem;
    margin-right: $small-space;
    margin-bottom: $small-space;
    font-size: $font-size-xs;
    color: $gray-900;
    background: $white;

    .close {
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
      font-size: $font-size-lg;
      color: $gray-900;
      opacity: 1;
    }
  }
}

.block-categories {
  padding: 1.563rem 1.25rem;
  margin-bottom: 1.563rem;
  background: $white;

  .h6 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .category-sub-menu {
    margin-top: 1rem;

    .category-sub-link {
      font-size: $font-size-sm;
    }

    li {
      position: relative;

      &[data-depth="1"] {
        margin-bottom: $small-space;
      }
    }

    li[data-depth="0"] > a {
      display: inline-block;
      width: 100%;
      padding-bottom: 0.1875rem;
      margin: 0.3125rem 0 0;
      font-weight: 600;
    }

    li:not([data-depth="0"]):not([data-depth="1"]) {
      padding-left: 0.3125rem;

      &::before {
        margin-right: 0.3125rem;
        content: "-";
      }
    }
  }

  a {
    color: $gray-900;
  }

  .collapse-icons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    cursor: pointer;

    &[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $brand-primary;
      }
    }

    .remove {
      display: none;
    }
  }

  .arrows {
    .arrow-right,
    .arrow-down {
      margin-left: 2px;
      font-size: $font-size-sm;
      cursor: pointer;

      &:hover {
        color: $brand-primary;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }

      .arrow-down {
        display: inline-block;
      }
    }
  }
}

.facets-title {
  color: $gray-900;
}

.advertising-block {
  margin-bottom: 1.563rem;

  img {
    width: 100%;
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #category {
    #left-column {

      #search_filters_wrapper {
        margin-right: -30px;
        margin-left: -30px;
      }

      #search_filter_controls {
        margin-bottom: 1rem;
        text-align: center;

        button {
          margin: 0 0.5rem;
        }
      }

      #search_filters {
        padding: 0;
        margin-bottom: 20px;
        border-top: 1px solid $gray-300;
        box-shadow: none;

        .ui-slider-horizontal {
          margin-bottom: 20px;
        }

        .facet {
          padding-top: 0;
          border-bottom: 1px solid $gray-300;

          .title {
            align-items: center;
            cursor: pointer;

            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          .facet-title {
            color: $gray-900;
            text-transform: uppercase;
          }

          .h6 {
            display: inline-block;
            padding: 1.25rem;
            margin-bottom: 0;
          }

          .navbar-toggler {
            display: inline-block;
            height: inherit;
            padding: 0 0.625rem 0 0;
          }

          .collapse {
            display: none;

            &.in {
              display: block;
            }
          }

          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              padding: 1.25rem;
              border-top: 1px solid $gray-300;
            }
          }
        }
      }
    }

    #search_filter_toggler {
      width: 100%;
    }
  }

  .products-sort-order {
    .select-title {
      margin-left: 0;
    }
  }

  .products-selection {
    h1 {
      padding-top: 0;
      margin-bottom: 1rem;
      text-align: center;
    }

    .showing {
      padding-top: 1rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .products-selection {
    .filter-button {
      padding-left: 0;
    }
  }

  #category {
    #left-column {
      #search_filters_wrapper {
        margin-right: -15px;
        margin-left: -15px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .category-cover {
    display: none;
  }

  .block-category {
    min-height: 0;
    margin-bottom: 0;
  }

  .card-block {
    padding: 0;
  }

  .card {
    background-color: inherit;
    border: none;
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
  #products {
    .products {
      justify-content: center;
    }
  }
}



//GRID PRODUCTS MINIATURES
.products {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.product-miniature {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

#product-list-header{
  background: #FFF5F0;
  border-radius: 30px 0px 0px 30px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  .col-text-category{
    display: flex;
    align-items: center;
    padding: 20px 50px;
    text-align: center;
    h1{
      font-family: $amithen;
      font-weight: normal;
      font-size: 87px;
      letter-spacing: 0.05em;
      line-height: 81px;
      color: #c8586c;
    }
    #category-description{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2d353f;
    }
  }
  .category-cover{
    width: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

#js-product-list{
  padding: 0 15px;
}
#js-product-list-top{
  display: flex;
  justify-content: space-between;
  .filter-product-list{
    display: flex;
    .PM_ASBlockOutputHorizontal{
      .card-header{
        display: none;
      }
      .card-block{
        padding: 0;
        margin-left: 25px;
        .PM_ASResetSearch{
          display: none;
        }
      }
      .PM_ASCriterionsGroup{
        padding: 0;
      }
      .PM_ASCriterions{
        position: relative;
        .PM_ASResetGroup{
          display: none;
        }
        .PM_ASCriterionsGroupTitle{
          background-color: #4c7360;
          padding: 12px 25px;
          border-color: #4c7360;
          text-transform: initial;
          border-radius: 30px;
          margin: 0 10px;
          cursor: pointer;
          .PM_ASCriterionsGroupName{
            font-family: $montserrat;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 0.05em;
            line-height: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            &:after{
              content:"";
              width: 17px;
              height: 17px;
              background-image: url("../img/more.svg");
              display: inline-block;
              margin-left: 15px;
            }
          }

        }
        .PM_ASCriterionsGroupOuter{
          max-height: 0;
          overflow: hidden;
          transition: max-height 1s;
          position: absolute;
          width: max-content;
          min-width: calc(100% + 40px);
          left: -20px;
          opacity: 0;
          z-index: 5;
          padding-top: 15px;
          .PM_ASCriterionStepEnable{
            border-radius: 30px;
            background: #fff5f0;
            box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
            padding: 15px 20px 0;
          }
        }
        &.PM_ASCriterionGroupToggleHover{
          .PM_ASCriterionsGroupOuter{
            opacity: 1;
            max-height: 1000px;
          }
        }
      }
    }
  }

  .sort-by-row{
    .btn-unstyle{
      background: #fff;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
      border: none;
      padding: 12px 25px;
      border-color: #fff;
      text-transform: initial;
      border-radius: 30px;
      font-family: $montserrat;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.05em;
      color: #4c7360;
      svg{
        margin-left: 8px;
      }
    }
  }
}

#js-product-list-footer{
  position: relative;
  &:before{
    content:"";
    width: 100vw;
    height:100%;
    background: #FFF5F0;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .card{
    background: transparent;
    .category-additional-description{
      padding: 50px 0 35px;
      *{
        font-family: $montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2d353f;
      }
    }
  }
}

@media(min-width: 1200px) {
  .product-miniature {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .layout-full-width .product-miniature {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media(min-width: 992px) {
  .product-miniature {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .layout-full-width .product-miniature {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 320px) {
  .product-miniature {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
//FIN GRID PRODUCTS MINIATURES

.product-miniature{
  .card-product{
    width: 100%;
    text-align: center;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
    .card-img-top{
      border-radius: 30px 30px 0 0;
      overflow: hidden;
    }
    .product-title{
      a{
        font-family: $montserrat;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: .03em;
        line-height: 26px;
        text-align: center;
        color: #2d353f;
        text-transform: uppercase;
        padding: 0 15px;
        display: block;
      }
    }
    .feature_miniature{
      font-family: $montserrat;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.05em;
      line-height: 24px;
      text-align: center;
      color: #2d353f;
      display: block;
    }
    .stock_miniature{
      display: inline-block;
      font-family: $montserrat;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.05em;
      color: #2d353f;
      padding: 8px 22px;
      background: #FCD7DD;
      border-radius: 30px;
      margin: 10px 0;
    }

    .add-to-cart{
      font-family: $montserrat;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      line-height: 20px;
      color: #fff;
      text-transform: initial;
      height: 49px;
      border-radius: 24.5px;
      background: #4c7360;
      border-color: #4c7360;
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s;
      opacity: 0;
      svg{
        margin-right: 10px;
      }
    }
    &:hover{
      .add-to-cart{
        transition: opacity 0.3s;
        opacity: 1;
        position: relative;
        z-index: 3;
      }
    }
    .product-list-actions{
      position: relative;
      min-height: 40px;
      &:before{
        content:"";
        width: 25px;
        height: 25px;
        background-image: url("../img/visibility.svg");
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .product-flags{
    align-items: end;
    padding: 10px 15px;
    .product-flag , .product-flag.on-sale{
        font-family: $montserrat;
        font-weight: 500 !important;
        font-size: 16px;
        letter-spacing: 0.05em;
        text-align: left;
        color: #2d353f;
        text-transform: initial;
        margin-top: 0.625rem !important;
      width: auto;
      background: #FCD7DD !important;
      border-radius: 30px;
      padding: 10px 20px;
      &.discount{
        display: none;
      }

    }

  }

}


body .elementor-widget .swiper-slide .product-miniature {
  min-width: 100%;
  padding: 15px!important;
}

.elementor-widget-product-grid .elementor-product-grid .product-miniature{
  width: 100%;
  max-width: 100%;
  padding: 0;
}

@media (max-width: 1199px){
  .product-miniature .card-product .product-title a{
    font-size: 17px;
    letter-spacing: .03em;
    line-height: 24px;
    padding: 0;
  }
  #js-product-list-top{
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px){
  .product-miniature .card-product .add-to-cart{
    opacity: 1;
  }

  #product-list-header .col-text-category h1{
    font-size: 55px;
    letter-spacing: .05em;
    line-height: 58px;
  }
  #product-list-header .col-text-category{
    padding: 20px 25px;
  }
  #product-list-header{
    border-radius: 20px;
  }
  .products-selection .showing{
    text-align: center;
  }
}

@media (max-width: 500px){
  .product-miniature {
    flex: 0 0 100%;
    max-width: 100%;
  }
}