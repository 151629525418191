#authentication,
#registration,
#password,
#my-account,
#identity,
#history,
#order-slip,
#module-psgdpr-gdpr,
#address, #addresses,
body#module-prestablog-blog,
#cart,
#cms,
#stores {
  h1, .h1 h2 {
    font-family: $amithen;
    font-weight: 400;
    font-size: 66px;
    letter-spacing: .05em;
    line-height: 70px;
    color: #c8586c;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 50px;
      letter-spacing: .05em;
      line-height: 55px;
    }
  }

  .white.h1 h2 {
    color: #fff;
  }

  .account-link {
    color: #c8586c;
    font-weight: bold;
  }

  .cart-grid {
    h1 {
      text-align: left;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px;
    color: #4c7360;
  }

  h3, h4 {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    color: #4c7360;
  }

  .text-left {
    h1, h2 {
      text-align: left;
    }
  }

  .swiper-container {
    border-radius: 30px;
    box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
    overflow: hidden;
  }

  #product-list-header {
    overflow: hidden;

    p {
      margin-bottom: 0;
    }

    .elementor-widget-image {
      height: 100%;
      position: absolute;

      * {
        height: 100%;
      }
    }
  }

  .img-border {

    img {
      border-radius: 30px;
      box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
      overflow: hidden;
    }

    &.pseudo-left {
      z-index: 2;
      position: relative !important;

      &:before {
        content: "";
        height: 450px;
        width: 320px;
        background-image: url(../img/before-faq.svg);
        position: absolute;
        display: block !important;
        background-size: contain;
        bottom: 0;
        background-repeat: no-repeat;
        z-index: -1;
        transform: translate(-75%, 0%);
      }
    }
  }

  .elementor-item {
    font-family: $montserrat;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.03em;
    line-height: 30px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;

    &:after, &:before {
      display: none;
    }
  }

  .white p {
    color: #fff !important;
    margin-bottom: 0;
  }

  .elementor-nav {
    margin-bottom: 0 !important;
  }

  #grid-rose {
    .elementor-column {
      .elementor-widget-wrap {
        padding: 30px;
        border-radius: 30px;
        background: #fff5f0;
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);

        h2 {
          font-family: $montserrat;
          font-weight: bold;
          font-size: 22px;
          letter-spacing: 0.03em;
          line-height: 30px;
          color: #2d353f;
          margin-bottom: 0;
        }
      }
    }
  }
}

#mpm_ms_cart_page_slider_container .mpm-ms-block-header-title{
  span {
    font-family: $amithen;
    font-weight: 400;
    font-size: 66px;
    letter-spacing: .05em;
    line-height: 70px;
    color: #c8586c;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 50px;
      letter-spacing: .05em;
      line-height: 55px;
    }
  }
}

#cms #content {
  padding: 0;
}

#registration {
  #content {
    max-width: 800px;
  }
}

#password, #identity, #address {
  #content {
    border-radius: 30px;
    background: #fff5f0;
    -webkit-box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
    box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
    padding: 15px;

    .btn {
      background-color: #4c7360;
      padding: 12px 25px;
      border-color: #4c7360;
      text-transform: initial;
      border-radius: 30px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;

      &[data-action="show-password"] {
        border-radius: 0px 30px 30px 0;
      }
    }

    .form-control {
      border-radius: 30px;

      &.js-visible-password {
        border-radius: 30px 0px 0px 30px;
      }
    }

    a:not(.btn) {
      color: #c8586c;
      font-weight: bold;
    }
  }
}


#authentication, #registration, {
  #content {
    max-width: 100%;

    .login-form, .after-login {
      border-radius: 30px;
      background: #fff5f0;
      -webkit-box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
      box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
      padding: 50px 15px;
      margin-top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 40px;

      .btn {
        background-color: #4c7360;
        padding: 12px 25px;
        border-color: #4c7360;
        text-transform: initial;
        border-radius: 30px;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;

        &[data-action="show-password"] {
          border-radius: 0px 30px 30px 0;
        }
      }

      .form-control {
        border-radius: 30px;

        &.js-visible-password {
          border-radius: 30px 0px 0px 30px;
        }
      }

      a:not(.btn) {
        color: #c8586c;
        font-weight: bold;
      }
    }
  }
}

.page-my-account #content .links a span.link-item {
  border-radius: 30px;
  background: #fff;
  -webkit-box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
  box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: .03em;
  line-height: 25px;
  text-transform: uppercase;
  padding: 0 15px;
  color: #2d353f;
  font-family: $montserrat;
}

.page-my-account #content .links a i {
  color: #c8586c;
}


#my-account {
  .links {
    margin: 0 auto;
  }

  .page-footer {
    a {
      background-color: #4c7360;
      padding: 12px 25px;
      border-color: #4c7360;
      color: #fff;
      text-transform: initial;
      margin-top: 15px;
      border-radius: 30px;
    }
  }
}

.block-categories .arrows .arrow-down:hover, .block-categories .arrows .arrow-right:hover, .block-categories .collapse-icons .add:hover, .block-categories .collapse-icons .remove:hover, .page-my-account #content .links a:hover i {
  color: #4c7360;
}

#content-wrapper #prestablog_menu_cat {
  margin-top: 60px;
  margin-bottom: 30px;

  nav ul {
    background: #fff5f0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      a, i {
        color: #2d353f;
      }
    }
  }
}

#module-prestablog-blog {
  .ek_prestablog {
    margin-bottom: 70px;

    .prestablog_more {
      a {
        text-decoration: underline;
        color: #2d353f;
      }

    }
  }

  #prestablog_article {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  #content-wrapper {
    #prestablogfront {
      h2 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
        color: #4c7360;
      }

      h3, h4 {
        font-weight: 600 !important;
        font-size: 24px !important;;
        margin-bottom: 10px;
        color: #4c7360 !important;;
      }
    }
  }

}

.ek_prestablog {
  h2.title {
    text-align: center;
  }
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4c7360;
  border-radius: 10px;
}

.text-right {
  text-align: right;
}

html body {
  font-family: $montserrat;
}

.input-group.focus {
  outline: none !important;

  input {
    border: 1px solid rgba(0, 0, 0, .25);
  }
}

.btn-primary, .elementor-contact-form .elementor-button {
  border: 1px solid;
  background: #4c7360;
  border-color: #4c7360;
  padding: 10px 25px;
  border-radius: 30px;
  font-size: 17px;
  transition-duration: 0.2s;
  outline: none;

  &:hover, &:focus, &:active, &:active:hover {
    background: #fff;
    color: #4c7360;
    transition-duration: 0.2s;
    border-color: #4c7360;
    outline: none;
  }

}

#blockcart-modal .modal-footer .btn-primary:hover {
  background: #fff;
}

body {
  #prestablogfront, .prestablogExtra {
    padding: 0;
    border: none;

    .ek-img-blog {
      margin-bottom: 50px;
      text-align: center;

      > img {
        max-height: 600px;
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
      }

      .infos-blog {
        .share {
          padding: 15px 0 0;
          display: flex;
          align-items: center;

          .soc-buttons {
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .soc-text {
              display: none;
            }

            li {
              margin-right: 5px;

              svg {
                width: 22px;
                height: 22px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

#contact {
  .form-control:disabled {
    background: #fff;
  }

  #content {
    padding: 0;
  }

  .contact-rich {
    margin-top: 2.25rem;
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, .16);

    h4 {
      font-weight: 700;
      font-size: 22px;
      letter-spacing: .03em;
      line-height: 30px;
      text-transform: uppercase;
      text-align: center;
    }

    .block {
      text-align: center;

      .icon {
        display: none;
      }
    }
  }

  .contact-form {
    h3 {
      font-family: $amithen;
      font-weight: 400;
      font-size: 65px;
      letter-spacing: .05em;
      line-height: 65px;
      color: #c8586c;
      text-transform: initial;
    }
  }
}

.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  background-color: #4c7360;
  border-color: #4c7360;
  color: #FFF;
}

body #mpm_ms_added_products_container .mpm-ms-cart-product-qty-container .cart-quantity > a > i {
  color: #4c7360;
}

#registration #content .btn {
  &:hover {
    color: #fff;
  }
}

#content-wrapper .products.motivationsale-sliders-block-content,
#mpm_ms_modal_cart_container{
  .mpm-ms-slider-product-container {
    padding: 15px;
    border-radius: 0;
    box-shadow: none;
    margin-right: 0 !important;
    .content {
      border-radius: 20px;
      background: #fff;
      box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
      overflow: hidden;
      .img_block{
        display: flex;
        justify-content: center;
      }
      .product-name{
        span{
          font-weight: 700;
          font-size: 16px;
          letter-spacing: .03em;
          line-height: 23px;
          text-transform: uppercase;
          display: block;
          color: #2d353f;
          padding: 0;
        }
      }
      .add_to_cart_block{
        padding: 0;
        margin-bottom: 0;
        button{
          font-family: "montserrat", sans-serif;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: .05em;
          line-height: 20px;
          color: #fff;
          text-transform: initial;
          width: 100%;
          &:hover{
            color: #4c7360;
          }
        }
      }
    }
  }
   .slick-list{
    padding: 0 15px;
     margin: 0;
  }
}

.product-miniature .product-flags li.product-flag{
  list-style: none;
}


#products{
  width: 100%;
}

#module-ambjolisearch-jolisearch{
  #product-list-header{
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: .03em;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 50px 0;
  }
}


@media (min-width: 1200px) {
  #contact #wrapper .container {
    width: 1300px;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  #contact .contact-rich h4 {
    font-size: 19px;
    letter-spacing: .03em;
    line-height: 23px;
  }
  #authentication #content .after-login, #authentication #content .login-form, #registration #content .after-login, #registration #content .login-form {
    height: auto;
  }
}

@media (max-width: 991px) {
  #contact .contact-form h3 {
    font-size: 50px;
    letter-spacing: .05em;
    line-height: 55px;
  }
}

@media (max-width: 767px) {
  form .form-control-label {
    text-align: left;
  }
  .cart-summary,
  #js-checkout-summary{
    .card-block {
      padding: 1.25rem 1.25rem .5rem;
    }
  }
}