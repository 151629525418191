#wrapper {
  .banner {
    display: block;
    margin-bottom: 1.5rem;
  }

  .breadcrumb {
    padding: 0;
    background: transparent;

    &[data-depth="1"] {
      display: none;
  }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      display: inline;
      font-weight: $font-weight-light;

      &::after {
        margin: 0.3125rem;
        color: $gray-700;
        content: "/";
      }

      &:last-child {
        margin: 0;
        color: $black;
        font-weight: $font-weight-medium;
        content: "/";

        &::after {
          content: "";
        }
      }

      a {
        color: $gray-900;
      }
    }
  }

   .breadcrumb{
     margin: 25px 0;
     li , a{
       font-family: $montserrat;
       font-weight: normal;
       font-size: 16px;
       line-height: 24px;
       color: #2d353f;
     }
  }
}
